let KoreanLan = {
    currentLocation: "현재 위치",
    topDeliver: "우리는 당신의 주문을 하루 안에 배달합니다",
    chooseLanguage: "언어를 선택하세요",
    forColaborate: "공동 작업용",
    navElectronics: "전자공학",
    navBooks: "책",
    navClothes: "옷",
    navShoeses: "신발",
    navAccsessories: "악세서리",
    navBeautyCare: "샴푸",
    navHealth: "건강",
    navLaptops: "노트북",
    navToys: "장난감",
    navWatches: "시계",
    NavCatalog: "카탈로그...",
    aboutOurCompany: "회사 소개",
    aboutHereYou: "여기에서 우리에 대해 자세히 알아볼 수 있습니다",
    aboutTitle: "",
    ForMoreDetails: "자세한 내용은",
    RandomProducts: "랜덤 제품",

    AboutPagesText: `모든상품마켓 - 여러분을 위한 온라인 쇼핑의 새로운 세계!
    다양한 상품군과 합리적인 가격으로 여러분에게 풍부한 쇼핑 경험을 제공합니다. 
    우리의 마켓에서는 최신 트렌드의 제품부터 실용적이고 다양한 상품들까지 다양한 선택이 가능합니다. 
    편리한 온라인 쇼핑을 즐기세요!`,
    AboutExperience: "경험",
    AboutExperienceTime: "1년",
    AboutProductsSold: "판매된 제품",
    AboutProductsSoldNumber: "17+ 제품",
    AboutSupport: "지원",
    AboutSupportTime: "온라인 24/7",

    pleaseReadWithAttention: "주의를 기울여 읽어주세요.!",
    pleaseReadWithAttentionText: `안녕하세요, 지금 저희 웹사이트에서 구매하고 싶으시면 이
    양식에 이름을 적어주시면 연락드리고 원하는 제품을 배송해 드리겠습니다.`,
    PleaseWriteYourContact: "연락처를 적어주세요",
    ContactName: "이름",
    InsertContactName: "이름 삽입",
    ContactNumber: "수",
    InsertContactNumber: "전화번호를 입력하세요",
    ProductId: "제품 ID",
    SendMessage: "메시지 보내기",


    ElectronicsProduct1: "삼성전자 갤럭시 S24 자급제",
    ElectronicsProduct1_title: "저장용량: 256",
    ElectronicsProduct2: "삼성전자 갤럭시 Z 플립5 5G 자급제",
    ElectronicsProduct2_title: "저장용량: 256",
    ElectronicsProduct3: "우먼스 갤럭시워치",
    ElectronicsProduct3_title: "4/5 호환 가능 스포츠루프 스트랩 + 케이스 세트 20 40 42 44 46 mm",
    ElectronicsProduct4: "1채널 블랙박스",
    ElectronicsProduct4_title: "1080p 고화질 녹화 메모리 32GB 증정",
    ElectronicsProduct5: "2023 애플워치 SE 2",
    ElectronicsProduct5_title: "세대 알루미늄 케이스",
    ElectronicsProduct6: "2K 12인치 스마트 미러 T5",
    ElectronicsProduct6_title: "2K 비디오 캡처",
    ElectronicsProduct7: "필립스 커널형 블루투스 무선 이어폰 TAT1108",
    ElectronicsProduct7_title: " 블루투스 무선 이어폰",

    BooksProduct1: "-",
    BooksProduct1_title: "-",

    ClothesProduct1: "구디프 남성용 소매 포인트 카라 베이직 폴로티",
    ClothesProduct1_title: "크기 : M, L, XL, 2XL, 3XL",
    ClothesProduct2: "포인트 슬립온 에슨 3cm YS2606",
    ClothesProduct2_title: "크기 : 250, 255, 260, 265, 270, 275, 280",
    ClothesProduct3: "남자 헨리넥 반팔티 브이넥 여름 오버핏 티셔츠",
    ClothesProduct3_title: "크기 : 95-105, 110-115, 120-125",
    ClothesProduct4: "스파오 수피마코튼 레터 반팔 티셔츠",
    ClothesProduct4_title: "크기 : S, M, L, XL",
    ClothesProduct5: "베씨 남성 발란스 라운드 반팔티셔츠 남자 맨투맨 여름",
    ClothesProduct5_title: "크기 : S(90), M(95), L(100), XL(105)",
    ClothesProduct6: "웨닐 남성용 유데밀 반팔티 WED_0028",
    ClothesProduct6_title: "크기 : S, M, L, XL, XXL, 3XL, 4XL",

    ShoesesProduct1: "디사트 아칸 에어 남성용 운동화+사은품",
    ShoesesProduct1_title: "크기 : 250, 255, 260, 265, 270, 275, 280",
    ShoesesProduct2: "휠라 뉴클라이드 커버 샌들 1SM01949E",
    ShoesesProduct2_title: "크기 : 250, 255, 260, 265, 270, 275, 280",
    ShoesesProduct3: "남자 런닝화 여름 남성 운동화 워킹화 헬스화 T8E95A",
    ShoesesProduct3_title: "크기 : 250, 255, 260, 265, 270, 275, 280",
    ShoesesProduct4: "NUNUHANA 남성용 경량 런닝 운동화 플라이 런닝화 조깅화",
    ShoesesProduct4_title: "크기 : 245, 250, 255, 260, 265, 270",
    ShoesesProduct5: "인디언 스웨이드 포인트 남성 운동화 스니커즈 2831",
    ShoesesProduct5_title: "크기 : 250, 255, 260, 265, 270, 275, 280",
    ShoesesProduct6: "아놀드파마 남성용 제이션 스니커즈",
    ShoesesProduct6_title: "크기 : 250, 255, 260, 265, 270, 275, 280",

    AccessuariesProduct1: "벤딕트 엠비언트 무드등 차량용 15W 고속 무선충전 거치대 루나스퀘어",
    AccessuariesProduct1_title: "각도조절 여부: 각도조절 가능 회전 가능여부: 헤드 회전가능",
    AccessuariesProduct2: "디카 코일 카매트 확장형 운전석 조수석 풀세트 국산차 수입차 외제차 전차종 4가지색상",
    AccessuariesProduct2_title: "색상계열: 블랙계열 카매트 구조: 코일구조매트 재질: PVC 차량 시리즈: 전차종",
    AccessuariesProduct3: "무로 카바이 차량용 극세사 초경량 2종 대형 먼지털이개 + 소형 털이개 + 전용비닐팩, 1세트",
    AccessuariesProduct3_title: "케이스 포함여부: 케이스 포함 털 소재: 극세사/면사 용도: 차량용 구성: 본품",
    AccessuariesProduct4: "카템 원터치 LED 차량용 재떨이 CT153",
    AccessuariesProduct4_title: "장착위치: 컵홀더 형태: 버튼형 종류: 차량용 재질: 플라스틱",
    AccessuariesProduct5: "1+1 독일 3IN1 무선다기능청소기 120000Pa 자동차 강력 무음 먼지 흡입/불어내기 고출력 키보드 청소",
    AccessuariesProduct5_title: "유무선 여부: 무선",
    AccessuariesProduct6: "아이나비 전방 FHD 후방HD 2채널 블랙박스 V Shot Prim",
    AccessuariesProduct6_title: "상시 전원 기능: 상시 전원 가능 해상도: 1920x 이상 프레임 수: 15fps 화질: Full HD ",

    HealthCareProduct1: "네이처 에너지 샴푸, 500ml, 2개",
    HealthCareProduct1_title: "유통기한 (사용기한 표시의무 대상 상품은 사용기한): 2026-01-09 이거나 그 이후인 상품 구성: 본품",
    HealthCareProduct2: "수오가닉 블랙빈 단백질샴푸 맥주효모, 1000ml, 2개",
    HealthCareProduct2_title: "유통기한 (사용기한 표시의무 대상 상품은 사용기한): 2027-01-05 이거나 그 이후인 상품 두피타입: 일반두피",
    HealthCareProduct3: "케라시스 데일리 두피 케어 샴푸, 1.5L, 1개",
    HealthCareProduct3_title: "유통기한 (사용기한 표시의무 대상 상품은 사용기한): 2025-05-23 이거나 그 이후인 상품 두피타입: 민감성두피",
    HealthCareProduct4: "엘라스틴 프로틴 클리닉 단백질 고영양 샴푸 플로럴 머스크향, 1L, 1개",
    HealthCareProduct4_title: "유통기한 (사용기한 표시의무 대상 상품은 사용기한): 2025-07-25 이거나 그 이후인 상품 모발타입: 손상모발",
    HealthCareProduct5: "케라시스 어드밴스드 앰플샴푸 모이스쳐, 600ml, 4개",
    HealthCareProduct5_title: "유통기한 (사용기한 표시의무 대상 상품은 사용기한): 2025-03-12 이거나 그 이후인 상품 두피타입: 일반두피",
    HealthCareProduct6: "엘라스틴 아미노 펩타이드 고영양 모발케어 샴푸, 600ml, 1개",
    HealthCareProduct6_title: "유통기한 (사용기한 표시의무 대상 상품은 사용기한): 2025-07-22 이거나 그 이후인 상품 두피타입: 일반두피",

    ToysProduct1: "조이키즈 달걀 모양맞추기",
    ToysProduct1_title: "최소연령: 37개월 색상계열: 멀티(혼합)컬러",
    ToysProduct2: "토도리브로 원목교구 칼라도형 맞추기 학습완구",
    ToysProduct2_title: "최소연령: 36개월 재질: 목재 인테리어 컨셉: 내추럴 색상계열: 멀티(혼합)컬러",
    ToysProduct3: "키움하우스 12종 브레인 교구",
    ToysProduct3_title: "최소연령: 3세 성별: 남여공용 재질: 목재",
    ToysProduct4: "일월전빵 베베토이 마블런 구슬굴리기 105p",
    ToysProduct4_title: "성별: 남여공용",
    ToysProduct5: "반디 오프로드 익스트림 무선차 완구",
    ToysProduct5_title: "색상계열: 그레이계열 사용대상 구분: 남성용",
    ToysProduct6: "비앤씨 스파이더 고스트 RC자동차",
    ToysProduct6_title: "최소연령: 6세 충전타입: 건전지식",

    WatchesProduct1: "남성용 올러바웃 야광 시계",
    WatchesProduct1_title: "출시 연도: 2022년도 형태: 원형 재질: 가죽 사용대상 구분: 남성용 색상계열: 실버계열",
    WatchesProduct2: "Cogot 남성 시계 브랜드 시계 방수 시계",
    WatchesProduct2_title: "색상계열: 블랙계열 사용대상 구분: 남성용",
    WatchesProduct3: "와이지엘 스위스 명품 스타일 남성용 손목시계 멀티 패션 쿼츠 고급 시계",
    WatchesProduct3_title: "밴드 형태: 밴드 분리형 색상계열: 블루계열 사용대상 구분: 남성용 무브먼트: 전자식/쿼츠",
    WatchesProduct4: "TianC-U 남성 패션 손목 시계",
    WatchesProduct4_title: "색상계열: 블랙계열 사용대상 구분: 남성용",
    WatchesProduct5: "포이데거 손목시계 3R 클레식",
    WatchesProduct5_title: "색상계열: 레드계열",
    WatchesProduct6: "프레시오 전자시계 스포츠시계 군인시계 N51-BR",
    WatchesProduct6_title: "색상계열: 블랙계열",

    BestProducts: "제일 제품",
};

let UzbekLan = {
    currentLocation: "Joylashuv",
    topDeliver: "Buyurtmangizni bir kun ichida etkazib beramiz",
    chooseLanguage: "Tilni Tanlang",
    forColaborate: "Hamkorlik Uchun",
    navElectronics: "Elektronika",
    navBooks: "Kitoblar",
    navClothes: "Kiyimlar",
    navShoeses: "poyafzal",
    navAccsessories: "Aksessuarlar",
    navBeautyCare: "Shampun",
    navHealth: "Salomatlik",
    navLaptops: "Kompyuterlar",
    navToys: "O'yinchoq",
    navWatches: "Sog'atlar",
    NavCatalog: "Ro'yhat...",
    aboutOurCompany: "Kompaniya Haqida",
    aboutHereYou: "Biz haqimizda ko'proq ma'lumot olishingiz mumkin shu yerda",
    aboutTitle: "",
    ForMoreDetails: "Qo'shimcha ma'lumot olish uchun bosing:",
    RandomProducts: "Tasodifiy mahsulotlar",

    AboutPagesText: `Barcha tovar bozori - siz uchun onlayn xarid qilishning yangi
    dunyosi! Mahsulotlarning keng doirasi va o'rtacha narxlari bilan sizga boy xarid
    qilish tajribasini taqdim etamiz. Bizning bozorda, siz amaliy va turli xil
    mahsulotlar uchun eng so'nggi yo'nalishlari tanlashingiz mumkin.
    Qulay onlayn xarid qilishdan zavqlaning!`,
    AboutExperience: "Tajriba",
    AboutExperienceTime: "1 yil",
    AboutProductsSold: "Sotilgan mahsulotlar",
    AboutProductsSoldNumber: "17+ mahsulotlar",
    AboutSupport: "Qo'llab-quvvatlash",
    AboutSupportTime: "Onlayn 24/7",

    pleaseReadWithAttention: "Iltimos, diqqat bilan o'qing!",
    pleaseReadWithAttentionText: `Assalomu alaykum hozir saytimizdan sotib olmoqchi bo'lsangiz bu
    Formani ismingiz bilan to'ldiring va biz siz bilan bog'lanamiz va o'zingiz xohlagan mahsulotni sizga jo'natamiz.`,
    PleaseWriteYourContact: "Iltimos, aloqa ma'lumotlarini yozing",
    ContactName: "Ismingiz",
    InsertContactName: "Ismingizni kiriting",
    ContactNumber: "Telefon raqamingiz",
    InsertContactNumber: "Telefon raqamingizni kiriting",
    ProductId: "Mahsulot ID",
    SendMessage: "Xabar joʻnatish",

    ElectronicsProduct1: "Samsung Galaxy S24",
    ElectronicsProduct1_title: "Hotira: 256",
    ElectronicsProduct2: "Samsung Galaxy Z Flip5 5G",
    ElectronicsProduct2_title: "Hotira: 256",
    ElectronicsProduct3: "Ayollar Galaxy Watch ",
    ElectronicsProduct3_title: "4/5 Mos keladigan Sportloop Strap + Case Set 20 40 42 44 46 mm",
    ElectronicsProduct4: "Video-Registrator",
    ElectronicsProduct4_title: "1080p yuqori aniqlikdagi yozuv xotirasi 32GB sovg'a",
    ElectronicsProduct5: "2023 Apple Watch SE 2",
    ElectronicsProduct5_title: "Aluminiy korpus",
    ElectronicsProduct6: "2K 12 dyuymli aqlli oyna T5",
    ElectronicsProduct6_title: "2K Videoga olish",
    ElectronicsProduct7: "Bluetooth Simsiz Earbuds TAT1108",
    ElectronicsProduct7_title: " Philips Kernel",

    BooksProduct1: "-",
    BooksProduct1_title: "-",

    ClothesProduct1: "Gudif Erkaklar Ko'ylagi Bo'laklar Tasma Asosiy Polloti",
    ClothesProduct1_title: "Hajmi: M, L, XL, 2XL, 3XL",
    ClothesProduct2: "Nuqta slip-ons Eson 3cm YS2606",
    ClothesProduct2_title: "O'lchamlari: 250, 255, 260, 265, 270, 275, 280",
    ClothesProduct3: "Erkaklar Genri Bo'yin Qisqa Ko'ylak",
    ClothesProduct3_title: "O'lchamlari: 95-105, 110-115, 120-125",
    ClothesProduct4: "Spao Sufima Cotton Letter Short Sleeve Futbolkasi",
    ClothesProduct4_title: "O'lcham : S, M, L, XL",
    ClothesProduct5: "Bessy erkaklar Uchun",
    ClothesProduct5_title: "Hajmi: S(90), M(95), L(100), XL(105)",
    ClothesProduct6: "Erkaklar Uchun Ko'ylak Tee WED_0028",
    ClothesProduct6_title: "O'lchamlari : S, M, L, XL, XXL, 3XL, 4XL",

    ShoesesProduct1: "Disat Akan Air Erkaklar Krossovkalari",
    ShoesesProduct1_title: "O'lchami : 250, 255, 260, 265, 270, 275, 280",
    ShoesesProduct2: "FILA Basanoshka 1SM01949E",
    ShoesesProduct2_title: "O'lchamlari : 250, 255, 260, 265, 270, 275, 280",
    ShoesesProduct3: "Erkaklar Yugurish Oyoq Kiyimlari Yozgi T8E95A",
    ShoesesProduct3_title: "O'lchami : 250, 255, 260, 265, 270, 275, 280",
    ShoesesProduct4: "NUNUHANA Menlik yengil yugurish oyoq kiyimlari",
    ShoesesProduct4_title: "O'lchamlari : 245, 250, 255, 260, 265, 270",
    ShoesesProduct5: "Hind Suede Point Erkaklar Oyoq Kiyimi 2831",
    ShoesesProduct5_title: "O'lchami : 250, 255, 260, 265, 270, 275, 280",
    ShoesesProduct6: "Arnold Pharma Erkaklar Oyoq Kiyimi",
    ShoesesProduct6_title: "O'lchami : 250, 255, 260, 265, 270, 275, 280",

    AccessuariesProduct1: "Bendict Ambient Mood Light Car 15W Tez Simsiz Zaryadlash Holder",
    AccessuariesProduct1_title: "Burish sozlanishi: Sozlanishi tiltable: Bosh aylanishi mumkin",

    AccessuariesProduct2: "Kovriklar Barcha avtomabillar uchun",
    AccessuariesProduct2_title: "Rangli oila: Qora avtomobil mat tuzilishi: Mat material: PVC Vehicle Series: Barcha modellar",
    AccessuariesProduct3: "Muro Kabai Car Microfiber Ultralight 2",
    AccessuariesProduct3_title: "Case Included: Case O'z ichiga olgan mo'ynali material: Microfiber",
    AccessuariesProduct4: "CATEM One Touch LED avtomobil Ashtray CT153",
    AccessuariesProduct4_title: "O'rnatish pozitsiyasi: kupe egasi Turi: tugma turi: Avtomobil materiali: plastmassa",
    AccessuariesProduct5: "1+1 Germaniya 3in1 Simsiz ko'p funktsiyali vakuumli tozalagich 120000Pa avtomobil kuchli jimgina changni susikatsiya qilish/Zarb yuqori quvvatli klaviatura tozalash",
    AccessuariesProduct5_title: "simsiz Ishlash",
    AccessuariesProduct6: "iNavi Front FHD Orqa HD 2 Kanal Dash Cam V Shot Prim",
    AccessuariesProduct6_title: "Har doim quvvat qobiliyati: Har doim qaror qabul qilish: 1920x yoki undan yuqori Frame Count: 15fps Sifat: Full HD",
    
    HealthCareProduct1: "Tabiat energetik shampun, 500ml, 2 shampun",
    HealthCareProduct1_title: "Eng yaxshi oldingi sana (muddati tugash muddatini ko'rsatish majburiyatiga bo'ysunadigan mahsulotlarning amal qilish muddati): 2026-01-09 yoki undan keyingi Mahsulot tarkibi: Asosiy mahsulot",
    HealthCareProduct2: "Sooganic Qora bean Protein Shampun Pivo mayiz, 1000ml, 2 dona",
    HealthCareProduct2_title: "Eng yaxshi oldingi sana (majburiy yorliga bo'ysunadigan mahsulotlarning amal qilish muddati): 2027-01-05 yoki undan keyingi bosh terisi turi: Normal Scalp",
    HealthCareProduct3: "Kerasys Kundalik Bosh terisi parvarishi Shampun, 1.5L, 1 Shampun",
    HealthCareProduct3_title: "Eng yaxshi oldingi sana (majburiy yorliga bo'ysunadigan mahsulotlar uchun amal qilish muddati): 2025-05-23 yoki undan keyingi bosh terisi Turi: Nozik bosh terisi",
    HealthCareProduct4: "Protein Yuqori Oziqlantirish Shampun Floral Musk Xushbo'y hid",
    HealthCareProduct4_title: "Eng yaxshi oldingi sana (Majburiy etiketaga bo'ysunadigan mahsulotlar uchun amal qilish muddati): 2025-07-25 yoki undan keyingi Soch turi: Shikastlangan sochlar",
    HealthCareProduct5: "Kerasys ilg'or ampula shampun namligi, 600ml, 4 dona",
    HealthCareProduct5_title: "Eng yaxshi sanasi (majburiy etiketkaga bo'ysunadigan mahsulotlar uchun amal qilish muddati): 2025-03-12 yoki undan keyingi bosh terisi turi: Normal bosh terisi",
    HealthCareProduct6: "Elastin Amino Peptide Yuqori oziqlantiruvchi soch parvarishi Shampun, 600ml, 1 Shampun",
    HealthCareProduct6_title: "Eng yaxshi sanasi (majburiy etiketkaga bo'ysunadigan mahsulotlar uchun amal qilish muddati): 2025-07-22 yoki undan keyingi bosh terisi turi: Normal bosh terisi",

    ToysProduct1: "JoyKidlar tuxum shakli",
    ToysProduct1_title: "Minimal yosh: 37 oy Rang Oila: Ko'p (Aralash) Rang",
    ToysProduct2: "Yog'och ta'lim vositasi",
    ToysProduct2_title: "Minimal yosh: 36 oy Material: Yog'och ichki tushunchasi: Tabiiy rang Oila: Ko'p (Aralash) Rang",
    ToysProduct3: "Kiwoom House 12 turdagi miya parisi",
    ToysProduct3_title: "Minimal yosh: 3 yosh Jinsi: Unisex Material: Yog'och",
    ToysProduct4: "Quyosh Oy Non Bebetoy Marmar Yugurish To'pi Rolling 105p",
    ToysProduct4_title: "Jinsi: Unisex",
    ToysProduct5: "Firefly Offroad Haddan tashqari simsiz avtomobil o'yinchoqlari",
    ToysProduct5_title: "Rang: Kulrang Klassifikatsiya: Erkaklar",
    ToysProduct6: "B&C O'rgimchak g'ayritabiiy RC avtomobili",
    ToysProduct6_title: "Eng kam yosh: 6 yosh Zaryadlash turi: Batareya bilan ishlaydigan",

    WatchesProduct1: "Erkaklar Allerbout Nurli Soat",
    WatchesProduct1_title: "Yil: 2022 Shakli: Yumaloq Material: Charm Tasnifi: Erkaklar Rangi: Kumush",
    WatchesProduct2: "Kogot erkaklar soati",
    WatchesProduct2_title: "Rang: Qora klassifikatsiya: Erkaklar, suv o'tkazmaydigan",
    WatchesProduct3: "WYZIEL Shveytsariya hashamatli soat",
    WatchesProduct3_title: "Band Turi: Band Ajraladigan rangi Oila: Ko'k Mo'ljal Klassifikatsiyasi: Erkaklar qo'zg'oloni: Elektron/Kvarts",
    WatchesProduct4: "TianC-U erkaklar moda bilagi soati",
    WatchesProduct4_title: "Rang: Qora klassifikatsiya: Erkaklar",
    WatchesProduct5: "Feuidegger Soati 3R klassik",
    WatchesProduct5_title: "Rang: Qora ichki qismi Qizil",
    WatchesProduct6: "Presio Elektron kuzatuv sporti harbiy kuzatuv N51-BR",
    WatchesProduct6_title: "Rang: Qora",

    BestProducts: "Eng Yaxshi Mahsulotlar",
};

let RussianLan = {
    currentLocation: "Вы здесь",
    topDeliver: "Доставляем ваш заказ в течение одного дня",
    chooseLanguage: "Выберите язык",
    forColaborate: "Для совместной работы",
    navElectronics: "Электроника",
    navBooks: "Книга",
    navClothes: "Одежда",
    navShoeses: "Обувь",
    navAccsessories: "Принадлежности",
    navBeautyCare: "Шампунь",
    navHealth: "Здоровье",
    navLaptops: "Ноутбуки",
    navToys: "Игрушки",
    navWatches: "Часы",
    NavCatalog: "Каталог...",
    aboutOurCompany: "О нашей компании",
    aboutHereYou: "Вы можете узнать больше о нас здесь",
    aboutTitle: "",
    ForMoreDetails: "Дополнительные сведения см. в разделе",
    RandomProducts: "Случайные продукты",

    AboutPagesText: `Новый мир интернет-покупок для Вас! Благодаря широкому ассортименту
    товаров и разумным ценам мы обеспечиваем вам богатый опыт покупок. 
    На нашем рынке вы можете выбрать от последних тенденций до практичных и разнообразных
    продуктов. Наслаждайтесь удобными онлайн-покупками!»`,
    AboutExperience: "Опыт",
    AboutExperienceTime: "1 год",
    AboutProductsSold: "Проданная продукция",
    AboutProductsSoldNumber: "17+ товаров",
    AboutSupport: "Поддержка",
    AboutSupportTime: "Онлайн 24/7",

    pleaseReadWithAttention: "Пожалуйста, прочтите внимательно!",
    pleaseReadWithAttentionText: `Здравствуйте, если вы хотите купить на нашем сайте сейчас, это
    Заполните форму, указав свое имя, и мы свяжемся с вами и отправим вам желаемый товар.`,
    PleaseWriteYourContact: "Пожалуйста, запишите свои контактные данные",
    ContactName: "Имя",
    InsertContactName: "Введите свое имя",
    ContactNumber: "Номер телефона",
    InsertContactNumber: "Введите свой номер телефона",
    ProductId: "Идентификаторы товаров",
    SendMessage: "Отправить сообщение",
    

    ElectronicsProduct1: "Samsung Galaxy S24",
    ElectronicsProduct1_title: "Хранение: 256",
    ElectronicsProduct2: "Samsung Galaxy Z Flip5 5G",
    ElectronicsProduct2_title: "Хранение: 256",
    ElectronicsProduct3: "Женские Galaxy Watch",
    ElectronicsProduct3_title: "4/5 совместимый ремешок Sportloop + чехол 20 40 42 44 46 мм",
    ElectronicsProduct4: "Видеорегистратор",
    ElectronicsProduct4_title: "Память высокой четкости 1080p 32 ГБ в подарок",
    ElectronicsProduct5: "Apple Watch SE 2 2023 г.",
    ElectronicsProduct5_title: "Поколение алюминиевого корпуса",
    ElectronicsProduct6: "2K 12-дюймовое умное зеркало T5",
    ElectronicsProduct6_title: "Захват видео в формате 2K",
    ElectronicsProduct7: "Наушники Philips Kernel TAT1108",
    ElectronicsProduct7_title: "Беспроводные наушники Bluetooth",

    BooksProduct1: "-",
    BooksProduct1_title: "-",

    ClothesProduct1: "Goodif Мужской воротник-пойнт с рукавом Basic Polloti",
    ClothesProduct1_title: "Размер: M, L, XL, 2XL, 3XL",
    ClothesProduct2: "Слипоны Eson 3см YS2606",
    ClothesProduct2_title: "Размеры: 250, 255, 260, 265, 270, 275, 280",
    ClothesProduct3: "Мужская футболка Henry Neck",
    ClothesProduct3_title: "Размеры: 95-105, 110-115, 120-125",
    ClothesProduct4: "Spao Sufima Хлопковое письмо Футболка",
    ClothesProduct4_title: "Размер: зять, мур, <unk>, нить",
    ClothesProduct5: "Bessy Мужская балансировочная круглая футболка",
    ClothesProduct5_title: "Размер: S (90), M (95), L (100), XL (105)",
    ClothesProduct6: "Мужская футболка WED_0028",
    ClothesProduct6_title: "Размеры: S, M, L, XL, 2XL, 3XL, 4XL",

    ShoesesProduct1: "Мужские кроссовки Disat Akan Air",
    ShoesesProduct1_title: "Размеры: 250, 255, 260, 265, 270, 275, 280",
    ShoesesProduct2: "Босоножки FILA Nucleide Cover 1SM01949E",
    ShoesesProduct2_title: "Размеры: 250, 255, 260, 265, 270, 275, 280",
    ShoesesProduct3: "Мужская беговая обувь Летние T8E95A",
    ShoesesProduct3_title: "Размеры: 250, 255, 260, 265, 270, 275, 280",
    ShoesesProduct4: "NUNUHANA Мужские легкие кроссовки",
    ShoesesProduct4_title: "Размеры: 245, 250, 255, 260, 265, 270",
    ShoesesProduct5: "Индийская замша Point Мужские Кроссовки 2831",
    ShoesesProduct5_title: "Размеры: 250, 255, 260, 265, 270, 275, 280",
    ShoesesProduct6: "Arnold Pharma Мужские кроссовки Jation",
    ShoesesProduct6_title: "Размеры: 250, 255, 260, 265, 270, 275, 280",

    AccessuariesProduct1: "Bendict Ambient Mood Light Авто 15 Вт Держатель для быстрой беспроводной зарядки",
    AccessuariesProduct1_title: "Регулируемый наклон: Регулируемый Наклоняемый: Вращающийся голова",
    AccessuariesProduct2: "Deca Coil Автоковрик Полный комплект Импортные автомобили",
    AccessuariesProduct2_title: "Цветовое семейство: Черный Автомобильный коврик Структура Все модели",
    AccessuariesProduct3: "Muro Kabai Авто Микрофибра Сверхлегкий 2",
    AccessuariesProduct3_title: "Чехол в комплекте: Чехол в комплекте Меховой материал: Микрофибра",
    AccessuariesProduct4: "CATEM One Touch Светодиодная автомобильная пепельница CT153",
    AccessuariesProduct4_title: "Монтажное положение: Тип подстаканника: Тип кнопки: Автомобильный Материал: Пластик",
    AccessuariesProduct5: "1+1 Германия 3 в 1 Беспроводной многофункциональный пылесос 120000 Па Авто Сильный Бесшумный Всасывание пыли / Выдув Высокая мощность Очистка клавиатуры",
    AccessuariesProduct5_title: "Проводной или беспроводной: Беспроводная связь",
    AccessuariesProduct6: "iNavi Передний FHD Задний HD 2-канальный видеорегистратор V Shot Prim",
    AccessuariesProduct6_title: "Возможность постоянного включения: Всегда включено Разрешение: 1920x или выше Количество кадров: 15 кадров в секунду Качество: Full HD",

    HealthCareProduct1: "Nature Energy Шампунь, 500мл, 2 шампуня",
    HealthCareProduct1_title: "Срок годности (Срок годности для продуктов, на которые распространяется обязательство отображать дату истечения срока годности): 2026-01-09 или более поздняя Состав продукта: Основной продукт",
    HealthCareProduct2: "Sooganic Black Bean Protein Шампунь с пивными дрожжами, 1000мл, 2 шт.",
    HealthCareProduct2_title: "Срок годности (срок годности для продуктов, подлежащих обязательной маркировке): 2027-01-05 или более поздняя версия Тип кожи головы: Нормальная Кожа головы",
    HealthCareProduct3: "Kerasys Ежедневный шампунь для ухода за кожей головы, 1,5 л, 1 шампунь",
    HealthCareProduct3_title: "Срок годности (срок годности для продуктов, подлежащих обязательной маркировке): 2025-05-23 или более поздняя Тип кожи головы: Чувствительная Кожа головы",
    HealthCareProduct4: "Protein High Nutrition Шампунь с цветочным мускусным ароматом",
    HealthCareProduct4_title: "Срок годности (Срок годности для продуктов, подлежащих обязательной маркировке): 2025-07-25 или позже Тип волос: Поврежденные волосы",
    HealthCareProduct5: "Kerasys Advanced Ампульный шампунь для увлажнения, 600мл, 4 шт.",
    HealthCareProduct5_title: "Срок годности (срок годности для продуктов, подлежащих обязательной маркировке): 2025-03-12 или более поздняя Тип кожи головы: Нормальная Кожа головы",
    HealthCareProduct6: "Elastin Amino Peptide Высокопитательный шампунь для ухода за волосами, 600 мл, 1 шампунь",
    HealthCareProduct6_title: "Срок годности (срок годности для продуктов, подлежащих обязательной маркировке): 2025-07-22 или позже Тип кожи головы: Нормальная Кожа головы",

    ToysProduct1: "Форма яйца JoyKids",
    ToysProduct1_title: "Минимальный возраст: 37 месяцев Цветовое семейство: Многоцветный (смешанный)",
    ToysProduct2: "Деревянный обучающий инструмент",
    ToysProduct2_title: "Минимальный возраст: 36 месяцев Материал: Дерево Концепция интерьера: Натуральный цвет Семейство: Многоцветное (смешанное)",
    ToysProduct3: "Дом Кивума 12 типов прихода мозга",
    ToysProduct3_title: "Минимальный возраст: 3 года Пол: унисекс Материал: Дерево",
    ToysProduct4: "Сан Мун Хлеб Bebetoy Marble Run Ball Rolling 105p",
    ToysProduct4_title: "Пол: Унисекс",
    ToysProduct5: "Firefly Внедорожные Экстремальные Беспроводные Автомобильные Игрушки",
    ToysProduct5_title: "Цвет: Серый Классификация: Мужская",
    ToysProduct6: "Радиоуправляемый автомобиль B&C Spider Ghost",
    ToysProduct6_title: "Минимальный возраст: 6 лет Тип зарядки: Питание от батареи",

    WatchesProduct1: "Мужские часы Allerbout Luminous",
    WatchesProduct1_title: "Год: 2022 Форма: Круглый Материал: Кожа Классификация: Мужской Цвет: Серебристый",
    WatchesProduct2: "Cogot Мужские часы",
    WatchesProduct2_title: "Цвет: Черный Классификация: Мужской, часы Водонепроницаемые часы",
    WatchesProduct3: "WYZIEL Швейцарский Роскошные Часы",
    WatchesProduct3_title: "Тип ремешка: Ремешок Съемный Семейство цветов: Синий Классификация мишеней: Мужской Механизм: Электронный/Кварцевый",
    WatchesProduct4: "TianC-U Мужские модные наручные часы",
    WatchesProduct4_title: "Цвет: Черный Классификация: Мужской",
    WatchesProduct5: "Часы Feuidegger 3R classic",
    WatchesProduct5_title: "Цвет: Красный",
    WatchesProduct6: "Электронные часы Presio Спортивные часы Военные часы N51-BR",
    WatchesProduct6_title: "Цвет: Черный",

    BestProducts: "Лучшие продукты",

};

function translateText() {
  let userLanguage = localStorage.getItem("language");

  if (userLanguage === "uz") {
    return UzbekLan;
  } else if (userLanguage === "ru") {
    return RussianLan;
  } else if (userLanguage === "kor") {
    return KoreanLan;
  }
  else {
    return UzbekLan;
  }
}

function editLanguage(event) {
  localStorage.setItem("language", event.target.getAttribute("alt"));
  window.location.reload();
}
  
  export { translateText, editLanguage };
  