import React from 'react'
import "./pageNoteFound.css";

const PageNoteFound = () => {
  return (
    <div className='PageNoteFound'>
        <h1>Sorry But Page Note Found.!🥴</h1>
    </div>
  )
}

export default PageNoteFound